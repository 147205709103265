import React, { useState, useRef } from "react";
import "../Documents/UploadDocuments.css";

const UploadDocuments = ({ user }) => {
  const [files, setFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [successMessage] = useState(null);


  const fileInputRef = useRef();

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const validFiles = selectedFiles.filter((file) =>
      [
        "image/*", 
        "application/pdf", 
        "application/vnd.ms-excel", 
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", 
        "application/msword", 
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document", 
      ].some((mimeType) => file.type.match(mimeType))
    );

    if (validFiles.length === selectedFiles.length) {
      setFiles(validFiles);
    } else {
      alert("Please upload only valid files (images, PDFs, Excel, or Word).");
    }
  };

  const handleRemoveFile = (fileName) => {
    setFiles(files.filter((file) => file.name !== fileName));
  };

  const handleDropzoneClick = () => {
    fileInputRef.current.click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    handleFileChange({ target: { files: droppedFiles } });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (files.length === 0) {
      alert("Please select at least one file.");
      return;
    }

    const encodedFiles = await Promise.all(
      files.map((file) =>
        new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            resolve({
              file_name: file.name,
              content: reader.result.split(",")[1],
            });
          };
        })
      )
    );

    const data = {
      userName: user.username,
      files: encodedFiles,
    };

    try {
      const response = await fetch("https://6elcbn2wjl.execute-api.us-east-1.amazonaws.com/saveinvestor", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      if (response.ok) {
        alert("Files uploaded successfully!");
        setFiles([]); 
      } else {
        alert(result.message || "Error uploading files.");
      }
    } catch (error) {
      alert("Error making request.");
    }
  };

  return (
    <div className="box-investors">
      <h3>Upload Documents</h3>

      <div className="contenedor-document">
        <h1>Dear {user?.username}</h1>

        <form onSubmit={handleSubmit} className="upload-form">
          <div
            className={`dropzone ${isDragging ? "dragging" : ""}`}
            onClick={handleDropzoneClick}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                handleDropzoneClick();
              }
            }}
          >
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              className="form-control"
              multiple
              accept="image/*, application/pdf, .doc, .docx, .xls, .xlsx"
              style={{ display: "none" }}
            />
            <span>Drag files here or click to select</span>
          </div>

          {files.length > 0 && (
            <div className="file-list">
              <h3>Selected Files:</h3>
              <ul>
                {files.map((file) => (
                  <li key={file.name}>
                    {file.name}
                    <button type="button" onClick={() => handleRemoveFile(file.name)}>
                      Remove
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}

          <div className="form-group hidden">
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              id="username"
              name="username"
              value={user?.username}
              readOnly
              className="form-control"
            />
          </div>

          <button type="submit" className="btn-submit">
            Upload Documents
          </button>
        </form>
        {successMessage && (
          <div className="success-message">{successMessage}</div>
        )}
      </div>
    </div>
  );
};

export default UploadDocuments;
