import React, { useState } from "react";
import logo from "../../images/Logo-B1Z-01.png";
import "./Header.css";
import MenuUser from "../Menu/MenuUser";
import useUserGroups from '../../hooks/useUserGroups';

const Header = ({ user, signOut }) => {
  const [openMenu, setOpenMenu] = useState(null);
  const userGroups = useUserGroups(); 

  const closeMenus = () => {
    setOpenMenu(null);
  };

  return (
    <div className="header-container">
      <header className="header">
        <div className="left-section">
          <div className="logo-container">
            <a href="/">
              <img src={logo} alt="Logo" className="logo" />
            </a>{" "}
          </div>
          <div className="separator-menu"></div>
        </div>

        <div className="center-section"></div>

        <div className="right-section">
          {userGroups.includes("AdminGroup") && (
            <ul className="menu-links">
              <li>
                <a href="/read-documents">Review Documents</a>
              </li>           
              <li>
                <a href="/investors">Upload Documents</a>
              </li>
              
            </ul>
          )}

          {userGroups.includes("InvestorGroup") && !userGroups.includes("AdminGroup") && (
            <ul className="menu-links">
              <li>
                <a href="/upload">Upload</a>
              </li>
              <li>
                <a href="/read-documents">Review Documents</a>
              </li>
            </ul>
          )}
        </div>
        {/* Botón Sign Out fuera del menú */}
        <button className="logout-button" onClick={signOut}>
          Sign out
        </button>
      </header>
      {openMenu === "user" && (
        <MenuUser user={user} signOut={signOut} closeMenu={closeMenus} />

      )}

      
    </div>
  );
};

export default Header;
