import React from "react";
import useUserGroups from "../../hooks/useUserGroups";
import ViewUser from "./ViewUser";
import ViewAdmin from "./ViewAdmin";
import "./ReadDocument.css";

const ViewDocuments = ({ user }) => {
  const userGroups = useUserGroups();
  
  if (userGroups.includes("AdminGroup")) {
    return <ViewAdmin user={user} />;
  }
  return <ViewUser user={user} />;
};

export default ViewDocuments;
