import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Amplify } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import LogoHeader from "./components/Login/LogoHeader";
import Footer from "./components/Login/Footer";
import awsExports from "./aws-exports";
import LoginForm from "./components/Login/LoginForm";
import UploadDocuments from "./components/Documents/UploadDocuments";
import Header from "./components/Header/Header";
import ViewDocuments from "./components/Documents/ViewDocuments";
import ProtectedRoute from "./components/ProtectedRoute";
import ReadInvestors from "./components/Investors/Investors"
import MyAccount from "./components/Documents/MyAccount";
import "./App.css"

Amplify.configure(awsExports);

function App({ signOut, user }) {
  const [localUser, setLocalUser] = useState(user);

  useEffect(() => {
    if (user) {
      setLocalUser(user);
    }
  }, [user]);

  return (
    <Router>
      <div className="app-container">
      {localUser && <Header user={localUser} signOut={signOut} />}
        <div className="main-content">
          <Routes>
            <Route
              path="/login"
              element={<LoginForm setUser={setLocalUser} />}
            />

            <Route
              path="/upload"
              element={
                <ProtectedRoute
                  allowedGroups={["AdminGroup", "InvestorGroup"]}
                  user={localUser}
                  signOut={signOut}
                  element={UploadDocuments}
                />
              }
            />

            <Route
              path="/read-documents"
              element={
                <ProtectedRoute
                allowedGroups={["AdminGroup", "InvestorGroup"]}
                user={localUser}
                  signOut={signOut}
                  element={ViewDocuments}
                />
              }
            />
              <Route
              path="/investors"
              element={
                <ProtectedRoute
                  allowedGroups={["AdminGroup"]}
                  user={localUser}
                  signOut={signOut}
                  element={ReadInvestors}
                />
              }
            />

              <Route
              path="/myaccount"
              element={
                <ProtectedRoute
                  allowedGroups={["InvestorGroup"]}
                  user={localUser}
                  signOut={signOut}
                  element={MyAccount}
                />
              }
            />





            <Route path="*" element={<LoginForm setUser={setLocalUser} />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default withAuthenticator(App, {
  components: {
    Header: LogoHeader,
    Footer: Footer,
  },
});
