import React, { useState, useEffect } from "react";
import "./ReadDocument.css";

const MyAccount = ({ user }) => {
  const [records, setRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredRecords, setFilteredRecords] = useState([]);

  useEffect(() => {
    const fetchRecords = async () => {
      try {
        const response = await fetch(
          "https://6elcbn2wjl.execute-api.us-east-1.amazonaws.com/readdocumentuser",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              action: "read_document_user",
              userName: user?.username, 
            }),
          }
        );

        if (!response.ok) {
          throw new Error(`Error fetching records: ${response.statusText}`);
        }

        const responseData = await response.json();
        const bodyData = JSON.parse(responseData.body);

        if (bodyData?.documents) {
          setRecords(bodyData.documents);
          setFilteredRecords(bodyData.documents);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    // Solo ejecutar si el usuario está logueado
    if (user?.username) {
      fetchRecords();
    }
  }, [user]);

  // Filtrar los registros según el término de búsqueda
  useEffect(() => {
    // Filtrar solo por el nombre del archivo (file_name)
    const filtered = records.filter((record) =>
      record.file_name
        ? record.file_name.toLowerCase().includes(searchTerm.toLowerCase())
        : false
    );
    setFilteredRecords(filtered);
  }, [searchTerm, records]);

  return (
    <div className="contenedor-read">
      <h1>My account, {user?.username}</h1>

      {/* Barra de búsqueda */}
      <input
        type="text"
        placeholder="Search by file name"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-bar"
      />

      <div className="table-container">
        {/* Tabla de registros */}
        <table className="document-table">
          <thead>
            <tr>
              <th className="download-links-col">My Documents</th>
            </tr>
          </thead>
          <tbody>
            {filteredRecords.map((record, index) => (
              <tr key={index}>
                <td className="download-links-col">
                  {/* Verificamos si los datos del archivo están vacíos */}
                  {record.url || record.file_name ? (
                    <div key={index}>
                      {record.file_name ? (
                        <a
                          href={record.url}
                          download
                          className="download-link"
                        >
                          ➤ {record.file_name || "Download"}
                        </a>
                      ) : (
                        <span>No file name</span>
                      )}
                    </div>
                  ) : (
                    <span>No files available</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MyAccount;
