import React, { useState, useEffect } from "react";
import "./ReadDocument.css";

const ViewAdmin = ({ user }) => {
  const [investorRecords, setInvestorRecords] = useState([]);
  const [adminRecords, setAdminRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredInvestorRecords, setFilteredInvestorRecords] = useState([]);
  const [filteredAdminRecords, setFilteredAdminRecords] = useState([]);

  // 🔍 Filtro de búsqueda
  useEffect(() => {
    const searchTermLower = searchTerm.toLowerCase();

    // Filtrar inversores
    const filteredInvestors = investorRecords.filter((record) => {
      return (
        (record.userName &&
          record.userName.toLowerCase().includes(searchTermLower)) ||
        (record.name && record.name.toLowerCase().includes(searchTermLower)) ||
        (record.lastname &&
          record.lastname.toLowerCase().includes(searchTermLower)) ||
        record.s3_files?.some((file) =>
          file.file_name.toLowerCase().includes(searchTermLower)
        )
      );
    });
    setFilteredInvestorRecords(filteredInvestors);

    // Filtrar documentos enviados por el admin
    const filteredAdmins = adminRecords.filter((record) => {
      return (
        (record.userName &&
          record.userName.toLowerCase().includes(searchTermLower)) ||
        record.files?.some((file) =>
          file.file_name.toLowerCase().includes(searchTermLower)
        )
      );
    });
    setFilteredAdminRecords(filteredAdmins);
  }, [searchTerm, investorRecords, adminRecords]);

  // 📩 Obtener documentos de inversores
  useEffect(() => {
    const fetchInvestorRecords = async () => {
      try {
        const response = await fetch(
          "https://6elcbn2wjl.execute-api.us-east-1.amazonaws.com/readdocument",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ action: "read_document" }),
          }
        );

        if (!response.ok) {
          throw new Error(
            `Error fetching investor records: ${response.statusText}`
          );
        }

        const responseData = await response.json();

        if (Array.isArray(responseData.data)) {
          setInvestorRecords(responseData.data);
          setFilteredInvestorRecords(responseData.data);
        } else {
          console.warn("⚠️ 'data' no es un array:", responseData.data);
          setInvestorRecords([]);
          setFilteredInvestorRecords([]);
        }
      } catch (error) {
        console.error("❌ Error fetching investor records:", error);
        setInvestorRecords([]);
        setFilteredInvestorRecords([]);
      }
    };

    fetchInvestorRecords();
  }, []);

  // 📂 Obtener documentos enviados por el admin
  useEffect(() => {
    const fetchAdminRecords = async () => {
      try {
        const response = await fetch(
          "https://6elcbn2wjl.execute-api.us-east-1.amazonaws.com/readdocumentinvestor",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ action: "all" }),
          }
        );

        if (!response.ok) {
          throw new Error(
            `Error fetching admin records: ${response.statusText}`
          );
        }

        const responseData = await response.json();
        if (responseData.body) {
          const parsedBody = JSON.parse(responseData.body);

          if (Array.isArray(parsedBody.documents)) {
            setAdminRecords(parsedBody.documents);
            setFilteredAdminRecords(parsedBody.documents);
          } else {
            console.warn(
              "⚠️ 'documents' no es un array:",
              parsedBody.documents
            );
            setAdminRecords([]);
            setFilteredAdminRecords([]);
          }
        } else {
          console.warn("⚠️ No se encontraron 'documents' en la respuesta.");
          setAdminRecords([]);
          setFilteredAdminRecords([]);
        }
      } catch (error) {
        console.error("❌ Error fetching admin records:", error);
        setAdminRecords([]);
        setFilteredAdminRecords([]);
      }
    };

    fetchAdminRecords();
  }, []);

  return (
    <div className="contenedor-read">
      <h1>Dear {user?.username}</h1>

      {/* Barra de búsqueda */}
      <input
        type="text"
        placeholder="Search by name, lastname, or file name"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-bar"
      />

      <div className="table-container">
        {/* Tabla de documentos de inversores */}
        <h2>Documents Uploaded by Investors</h2>
        <table className="document-table">
          <thead>
            <tr>
              <th>Username</th>
              <th>Name</th>
              <th>Lastname</th>
              <th>Email</th>
              <th className="download-links-col">Download Links</th>
            </tr>
          </thead>
          <tbody>
            {filteredInvestorRecords.length > 0 ? (
              filteredInvestorRecords.map((record, index) => (
                <tr key={index}>
                  <td>{record.userName || "N/A"}</td>
                  <td>{record.name || "N/A"}</td>
                  <td>{record.lastname || "N/A"}</td>
                  <td>{record.email || "N/A"}</td>
                  <td className="download-links-col">
                    {Array.isArray(record.s3_files) &&
                    record.s3_files.length > 0
                      ? record.s3_files.map((file, fileIndex) => (
                          <div key={fileIndex}>
                            <a
                              href={file.file_url}
                              download
                              className="download-link"
                            >
                              ➤ {file.file_name || "Download"}
                            </a>
                            <br />
                            <span style={{ fontSize: "0.8em", color: "#555" }}>
                              📅{" "}
                              {file.upload_date
                                ? new Date(file.upload_date).toLocaleString()
                                : "N/A"}
                            </span>
                          </div>
                        ))
                      : "No files available"}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">No investor documents found.</td>
              </tr>
            )}
          </tbody>
        </table>

        {/* 📌 Tabla de documentos enviados por el administrador */}
        <h2>Documents Sent by Admin</h2>
        <table className="document-table">
          <thead>
            <tr>
              <th>Username</th>
              <th className="download-links-col">Download Links</th>
            </tr>
          </thead>
          <tbody>
            {filteredAdminRecords.length > 0 ? (
              filteredAdminRecords.map((record, index) => (
                <tr key={index}>
                  <td>{record.userName || "N/A"}</td>
                  <td className="download-links-col">
                    {Array.isArray(record.files) && record.files.length > 0
                      ? record.files.map((file, fileIndex) => (
                          <div key={fileIndex}>
                            <a
                              href={file.file_url}
                              download
                              className="download-link"
                            >
                              ➤ {file.file_name || "Download"}
                            </a>
                            <br />
                            <span style={{ fontSize: "0.8em", color: "#555" }}>
                              📅{" "}
                              {file.upload_date
                                ? new Date(file.upload_date).toLocaleString()
                                : "N/A"}
                            </span>
                          </div>
                        ))
                      : "No files available"}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3">No admin documents found.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ViewAdmin;
