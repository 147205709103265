import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import "../Documents/UploadDocuments.css";

const Investors = ({ user }) => {
  const [files, setFiles] = useState([]);
  const [selectedInvestor, setSelectedInvestor] = useState(null);
  const [email, setEmail] = useState(""); // Nuevo estado para email
  const [isDragging, setIsDragging] = useState(false);
  const [investorOptions, setInvestorOptions] = useState([]);
  const [error] = useState(null);

  const fileInputRef = useRef();

  // Obtener lista de inversores
  useEffect(() => {
    fetch("https://6elcbn2wjl.execute-api.us-east-1.amazonaws.com/readuser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ action: "read_user" }),
    })
      .then((response) => response.json())
      .then((data) => {
        const investors = data.map((user) => ({
          value: user.document_id,
          label: user.userName,
          email: user.email || "", // Guardar el email si existe
        }));
        setInvestorOptions(investors);
      })
      .catch((error) => {
        alert("Failed to fetch investor data.");
        console.error("Error fetching investors:", error);
      });
  }, []);

  // Manejo de selección de inversor
  const handleInvestorChange = (selectedOption) => {
    setSelectedInvestor(selectedOption);
    setEmail(selectedOption?.email || ""); // Cargar email si existe
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);
  };

  const handleRemoveFile = (fileName) => {
    setFiles(files.filter((file) => file.name !== fileName));
  };

  const handleDropzoneClick = () => {
    fileInputRef.current.click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    handleFileChange({ target: { files: droppedFiles } });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedInvestor || files.length === 0) {
      alert("Please select an investor and upload at least one file.");
      return;
    }

    const isAdmin = true;

    const encodedFiles = await Promise.all(
      files.map(
        (file) =>
          new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
              resolve({
                file_name: file.name,
                content: reader.result.split(",")[1],
              });
            };
          })
      )
    );

    const data = {
      userName: selectedInvestor.label,
      name: user?.username,
      lastname: user?.lastname,
      email: email || "", // Enviar email si existe
      files: encodedFiles,
      is_admin: isAdmin,
    };

    try {
      const response = await fetch(
        "https://6elcbn2wjl.execute-api.us-east-1.amazonaws.com/saveadmin",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        }
      );

      const result = await response.json();

      if (response.ok) {
        console.log("Files uploaded successfully:", result);
        alert("Files uploaded successfully!");
      } else {
        console.error("Error uploading files:", result.message);
        alert(result.message || "Error uploading files.");
      }
    } catch (error) {
      console.error("Error sending request:", error);
      alert("Error sending request.");
    }
  };

  return (
    <div className="box-investors">
      <h3>Send documents to investors</h3>

      <div className="contenedor-document">
        <h1>Dear {user?.username} </h1>

        <form onSubmit={handleSubmit} className="upload-form">
          <div
            className={`dropzone ${isDragging ? "dragging" : ""}`}
            onClick={handleDropzoneClick}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                handleDropzoneClick();
              }
            }}
          >
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              className="form-control"
              multiple
              accept="image/*, application/pdf, .doc, .docx, .xls, .xlsx"
              style={{ display: "none" }}
            />
            <span>Drag files here or click to select</span>
          </div>

          {error && <div className="error-message">{error}</div>}

          {files.length > 0 && (
            <div className="file-list">
              <h3>Selected Files:</h3>
              <ul>
                {files.map((file) => (
                  <li key={file.name}>
                    {file.name}
                    <button
                      type="button"
                      onClick={() => handleRemoveFile(file.name)}
                    >
                      Remove
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}

          <div className="form-group">
            <label htmlFor="investor">Investor:</label>
            <Select
              id="investor"
              options={investorOptions}
              value={selectedInvestor}
              onChange={handleInvestorChange}
              isClearable
              placeholder="Search for an Investor"
            />
          </div>

          {selectedInvestor && (
            <div className="selected-investor form-group">
              <p>Selected Investor: {selectedInvestor.label}</p>
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter or update email"
                className="form-control"
              />
            </div>
          )}

          <button type="submit" className="btn-submit">
            Upload Documents
          </button>
        </form>
      </div>
    </div>
  );
};

export default Investors;
