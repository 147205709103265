import React, { useState, useEffect } from "react";
import "./ReadDocument.css";

const ViewUser = ({ user }) => {
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [receivedDocuments, setReceivedDocuments] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchDocuments = async (
      endpoint,
      action,
      setDocuments,
      setUserInfo = null
    ) => {
      try {
        const response = await fetch(endpoint, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ action, userName: user?.username }),
        });

        if (!response.ok) throw new Error(`Error fetching ${action}`);

        const responseData = await response.json();
        const parsedBody = responseData?.body
          ? JSON.parse(responseData.body)
          : {};

        if (parsedBody?.documents) {
          setDocuments(parsedBody.documents);
        }

        if (parsedBody?.user && setUserInfo) {
          setUserInfo(parsedBody.user);
        }

        const enrichedDocuments = parsedBody.documents.map((file) => ({
          ...file,
          document_id: parsedBody.document_id || "N/A", // Si no existe, asigna "N/A"
        }));

        setDocuments(enrichedDocuments);
      } catch (error) {
        console.error(`❌ Error fetching ${action}:`, error);
      }
    };

    if (user?.username) {
      fetchDocuments(
        "https://6elcbn2wjl.execute-api.us-east-1.amazonaws.com/readdocumentuser",
        "read_document_user",
        setUploadedDocuments,
        setUserInfo
      );

      fetchDocuments(
        "https://6elcbn2wjl.execute-api.us-east-1.amazonaws.com/readdocumentinvestor",
        "read_document_investor",
        setReceivedDocuments
      );
    }
  }, [user]);

  return (
    <div className="contenedor-read">
      <h1>My Account, {user?.username}</h1>

      <input
        type="text"
        placeholder="Search by file name"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-bar"
      />

      {/* Documentos subidos por el usuario */}
      <h2>My Uploaded Documents</h2>
      <UploadedDocumentsTable
        documents={uploadedDocuments}
        searchTerm={searchTerm}
        userInfo={userInfo}
      />

      {/* Documentos enviados por el administrador */}
      <h2>Documents Sent by Admin</h2>
      <ReceivedDocumentsTable
        documents={receivedDocuments}
        searchTerm={searchTerm}
      />
    </div>
  );
};

// 📌 TABLA: "My Uploaded Documents"
const UploadedDocumentsTable = ({ documents, searchTerm }) => {
  return (
    <table className="document-table">
      <thead>
        <tr>
          <th>File Name (Download Link)</th>
          <th>Upload Date</th>
        </tr>
      </thead>
      <tbody>
        {documents.length === 0 ? (
          <tr>
            <td colSpan="2">No documents available</td>
          </tr>
        ) : (
          documents.flatMap((doc, docIndex) =>
            doc.files
              ? doc.files
                  .filter((file) =>
                    file.file_name
                      ?.toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  )
                  .map((file, fileIndex) => (
                    <tr key={`${docIndex}-${fileIndex}`}>
                      <td>
                        {file.file_url ? (
                          <a
                            href={file.file_url}
                            download
                            className="download-link"
                          >
                            ➤ {file.file_name}
                          </a>
                        ) : (
                          "N/A"
                        )}
                      </td>
                      <td>
                        {file.upload_date
                          ? new Date(file.upload_date).toLocaleString()
                          : "N/A"}
                      </td>
                    </tr>
                  ))
              : []
          )
        )}
      </tbody>
    </table>
  );
};

// 📌 TABLA: "Documents Sent by Admin"
const ReceivedDocumentsTable = ({ documents, searchTerm }) => {
  return (
    <table className="document-table">
      <thead>
        <tr>
          <th>File Name (Download Link)</th>
          <th>Upload Date</th>
        </tr>
      </thead>
      <tbody>
        {documents.length === 0 ? (
          <tr>
            <td colSpan="3">No documents available</td>
          </tr>
        ) : (
          documents.flatMap((doc, docIndex) => {
            return doc.files
              ? doc.files
                  .filter((file) =>
                    file.file_name
                      ?.toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  )
                  .map((file, fileIndex) => (
                    <tr key={`${docIndex}-${fileIndex}`}>
                      <td>
                        {file.file_url ? (
                          <a
                            href={file.file_url}
                            download
                            className="download-link"
                          >
                            ➤ {file.file_name}
                          </a>
                        ) : (
                          "N/A"
                        )}
                      </td>
                      <td>
                        {file.upload_date
                          ? new Date(file.upload_date).toLocaleString()
                          : "N/A"}
                      </td>
                    </tr>
                  ))
              : [];
          })
        )}
      </tbody>
    </table>
  );
};

export default ViewUser;
